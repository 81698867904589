import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => (
  <Seo
    title="Aider les restaurants asiatiques dans leur stratégie web et mobile"
    description="Paris asiatique aide les restaurants asiatiques dans leur stratégie web et mobile : création de site vitrine, plateforme de réservation, site e-commerce, optimisation du référencement Seo, etc."
  />
)

const IndexPage = () => (
  <Layout content="full">
    <div>
      <header className="section hero">
        <h1>
          Nous aidons les restaurants asiatiques dans leur stratégie web et
          mobile
        </h1>
      </header>
      <section>
        <h2>Générez directement du chiffre d'affaires en ligne</h2>
        <p>
          Trop souvent, les restaurateurs s'en remettent à des plateformes en
          ligne pour générer du chiffre d'affaires : LaFourchette pour les
          réservations, Just Eat, Uber Eats ou Deliveroo pour les livraisons.
        </p>
        <p>
          Nous aidons les restaurateurs qui veulent générer du chiffre
          d'affaires sur le web ou sur mobile sans intermédiaire.
        </p>
      </section>
      <section>
        <h2>Gérez facilement vos réservations et vos ventes à emporter</h2>
        <p>
          Paris asiatique vous aide à créer un site vitrine bien référencé sur
          le web et sur mobile, une plateforme de réservation pour votre
          établissement ou un site e-commerce pour gérer vos ventes à emporter.
        </p>
      </section>
      <section>
        <h2>
          Améliorez votre réputation en ligne avec les avis de vos clients
        </h2>
        <p>
          Paris asiatique propose un outil pour vous aider à suivre et
          comprendre les avis en ligne sur votre restaurant. En analysant les
          retours de vos clients, vous pouvez ajuster votre menu, répondre
          rapidement aux commentaires, et renforcer votre réputation digitale.
          Cet outil vous permet de prendre des décisions éclairées pour offrir
          la meilleure expérience possible à votre clientèle.
        </p>
      </section>
      <section>
        <h2>Confiez votre projet web ou mobile à un expert</h2>
        <p>
          Joachim crée des sites internet depuis 20 ans en freelance sous le nom{" "}
          <a
            title="Solygambas"
            href="https://solygambas.github.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solygambas
          </a>
          . Il a accompagné plus de 500 porteurs de projets sur leur business
          plan, la création et la monétisation d'applications web et mobiles
          depuis 2012.
        </p>
        <p>
          Passionné par l'Inde, il a animé pendant 8 ans un site de bonnes
          adresses de restaurants asiatiques à Paris. Il met désormais cette
          expérience à votre service.
        </p>

        <button onClick={() => navigate("/contact")} className="cta">
          Contactez-nous dès maintenant !
        </button>
      </section>
    </div>
  </Layout>
)

export default IndexPage
